import { RuntimeConfig } from '@nuxt/schema'
import axios, { AxiosRequestConfig } from 'axios'
import { handleErrors, getApiBaseUrl } from '~/api/common'

interface IActionsPayload {
  settingsId: string;
  name: string;
  actionId: string;
  query: any;
  body: any;
}

export const actionsApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/actions',
    withCredentials: true,
    validateStatus (status) {
      // When all methods will be rewritten in monads, make this always return true(to don't throw exceptions)
      return status >= 200 && status < 500
    }
  })

  return {
    async getMyActions (actionsDto: any) {
      const config: AxiosRequestConfig = {
        headers: {}
      }

      if (actionsDto?.cookies) {
        config.headers!.Cookie = actionsDto.cookies
      }

      if (actionsDto?.settingsId) {
        config.params = {
          settingsId: actionsDto.settingsId
        }
      }
      const { data: payload } = await axiosBase.get('/', config)

      return handleErrors(payload)
    },
    async getAction (id: string) {
      const { data: payload } = await axiosBase.get(`/${id}`)

      return handleErrors(payload)
    },
    async createAction ({ settingsId, body, query }: Pick<IActionsPayload, 'settingsId' | 'body' | 'query'>) {
      const { data: payload } = await axiosBase.post('/create', {
        settingsId,
        body,
        query
      })

      return handleErrors(payload)
    },
    async createActionEvent ({ settingsId, name, actionId }: Pick<IActionsPayload, 'settingsId' | 'name' | 'actionId'>) {
      const { data: payload } = await axiosBase.post('/create-action-event', {
        settingsId,
        actionId,
        name
      })

      return handleErrors(payload)
    },
    async updateAction ({ actionId: id, body, query }: Pick<IActionsPayload, 'actionId' | 'body' | 'query'>) {
      const { data: payload } = await axiosBase.post('/edit', {
        id,
        body,
        query
      })

      return handleErrors(payload)
    },
    async deleteAction ({ settingsId, actionId }: Pick<IActionsPayload, 'settingsId' | 'actionId'>) {
      const { data: payload } = await axiosBase.post('/delete', {
        settingsId, actionId
      })

      return handleErrors(payload)
    },
    async getPersonalOfferHistoricalCount ({ actionId }: Pick<IActionsPayload, 'actionId'>) {
      const { data: payload } = await axiosBase.get('/personal-offers-historical-count/' + actionId)

      return handleErrors(payload)
    },
    async increasePersonalOfferHistoricalCount ({ actionId }: Pick<IActionsPayload, 'actionId'>) {
      const { data: payload } = await axiosBase.post('/personal-offers-historical-count/increase/', {
        actionId
      })

      return handleErrors(payload)
    }
  }
}
