import { RuntimeConfig } from '@nuxt/schema'
import axios, { AxiosRequestConfig } from 'axios'
import { handleErrors, getApiBaseUrl } from '~/api/common'

interface ICreateIntegration {
  settingsId: string;
  type: string;
  ym: {
    counter: string;
  }
  ga: {
    counter: string;
  },
  coMagic: {
    counter: string;
  },
  uis: {
    counter: string;
  },
  callTouch: {
    counter: string;
  }
  callTracking: Record<string, any>
}

export const integrationsApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/integrations',
    withCredentials: true,
    validateStatus: () => true
  })

  return {
    async getIntegrations (cookies: string) {
      const config: AxiosRequestConfig = {
        headers: {}
      }
      if (cookies) {
        config.headers!.Cookie = cookies
      }
      const { data: payload } = await axiosBase.get('/', config)

      return handleErrors(payload)
    },
    async createIntegration (createIntegrationPayload: ICreateIntegration) {
      const { data: payload } = await axiosBase.post('/create', createIntegrationPayload)

      return handleErrors(payload)
    },
    async deleteIntegration (integrationId: string) {
      const { data: payload } = await axiosBase.post('/delete', {
        integrationId
      })

      return handleErrors(payload)
    }
  }
}
