import { useApi } from '~/composables/useApi'
import type { Settings } from '~/types/settings'
import { useActionsStore } from '~/store/actions'

interface State {
  currentSettingsId: string | null,
  settings: Settings[],
  hasWidgetThreeClicks: boolean,
  isTariffBannerVisible: boolean
}

export const useSettingsStore = defineStore('settings', {
  state: (): State => ({
    currentSettingsId: null,
    settings: [],
    hasWidgetThreeClicks: false,
    isTariffBannerVisible: false
  }),
  getters: {
    isSettingsReady: state =>
      (state.settings.find(settings => settings.id === state.currentSettingsId) ?? state.settings[0])?.whatsappEnabled ||
      (state.settings.find(settings => settings.id === state.currentSettingsId) ?? state.settings[0])?.telegramEnabled,
    isWhatsappEnabled: state => (state.settings.find(settings => settings.id === state.currentSettingsId) ?? state.settings[0]).whatsappEnabled,
    scriptLink: (state) => {
      return (appDomain: string) => `<script>(function () { var widget = document.createElement('script'); widget.defer = true; widget.dataset.pfId = '${state.currentSettingsId}'; widget.src = 'https://${appDomain}/script/widget.js?id=${state.currentSettingsId}&now='+Date.now(); document.head.appendChild(widget); })()</script>`
    },
    currentSettings: state => state.settings.find(settings => settings.id === state.currentSettingsId) ?? state.settings[0],
    widgetCount: state => state.settings.length
  },
  actions: {
    // Actions
    async getSettings (rawCookies: string | null) {
      const api = useApi()
      const getEither = await api.settingsApi.getSettings(rawCookies)

      // eslint-disable-next-line array-callback-return
      getEither.map((settings: any) => {
        this.settings = settings
      })

      return getEither
    },
    async updateSettings (updateSettings: Partial<Settings>) {
      const actionsStore = useActionsStore()
      const api = useApi()

      const updateEither = await api.settingsApi.updateSettings({
        ...this.currentSettings,
        ...updateSettings
      })

      updateEither.mapRight((updatedSettings) => {
        this.UPDATE_SETTINGS(updatedSettings)
      })

      await actionsStore.getMyActions({ settingsId: this.currentSettings.id })

      return updateEither
    },
    async addSettings (name: string) {
      const actionsStore = useActionsStore()
      const api = useApi()

      const addEither = await api.settingsApi.addSettings(name)

      addEither.mapRight(({ newSettings }) => {
        this.ADD_SETTINGS(newSettings)
        this.SET_CURRENT_SETTINGS_ID(newSettings.id)
      })

      await actionsStore.getMyActions({ settingsId: this.currentSettings.id })

      return addEither
    },
    async renameSettings (name: string, settingsId: string) {
      const api = useApi()
      const renameEither = await api.settingsApi.renameSettings({
        settingsId,
        name
      })

      renameEither.mapRight(({ settings }) => {
        this.UPDATE_SETTINGS(settings)
      })

      return renameEither
    },
    resetCurrentSettings (settingsId: string | null) {
      this.SET_CURRENT_SETTINGS_ID(settingsId)
    },
    async deleteSettings (settingsId: string) {
      const api = useApi()
      const deleteEither = await api.settingsApi.deleteSettings(settingsId)

      deleteEither.mapRight(({ settings }) => {
        this.SET_SETTINGS(settings)
        this.SET_CURRENT_SETTINGS_ID(settings[settings.length - 1].id)
      })

      return deleteEither
    },
    async checkHasWidgetThreeClicks () {
      this.SET_TARIFF_BANNER_VISIBILITY(false)
      const api = useApi()
      try {
        const settingsId = this.currentSettings?.id
        const countEither = await api.statisticsApi.checkHasWidgetThreeClicks(settingsId)
        countEither.mapRight(({ result }) => {
          this.SET_HAS_WIDGET_THREE_CLICKS(result)
        })
      } finally {
        this.SET_TARIFF_BANNER_VISIBILITY(true)
      }
    },
    // Mutations
    SET_SETTINGS (payload: Settings[]) {
      this.settings = payload
    },
    UPDATE_SETTINGS (payload: Settings) {
      this.settings = this.settings.map(oldSettings => oldSettings.id === payload.id ? payload : oldSettings)
    },
    ADD_SETTINGS (payload: Settings) {
      this.settings.push(payload)
    },
    SET_CURRENT_SETTINGS_ID (payload: string | null) {
      this.currentSettingsId = payload
    },
    SET_HAS_WIDGET_THREE_CLICKS (value: boolean) {
      this.hasWidgetThreeClicks = value
    },
    SET_TARIFF_BANNER_VISIBILITY (value: boolean) {
      this.isTariffBannerVisible = value
    }
  }
})
