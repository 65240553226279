import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { useAuthStore } from '~/store/auth'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  if (!config.public.IS_PROD || !config.public.IS_STAGING || !config.public.SENTRY_DSN) {
    return
  }

  if (process.client) {
    initSentryClient()
  }

  const authStore = useAuthStore()
  watchEffect(() => {
    if (authStore.user) {
      Sentry.setUser({
        id: authStore.user.id,
        username: authStore.user.username
      })
    } else {
      Sentry.setUser(null)
    }
  })
})

// export default function ({ store, $sentry }) {
//   store.watch(
//     state => state.auth.user,
//     (user) => {
//       if (user) {
//         $sentry.setUser({
//           id: user.id,
//           username: user.username
//         })
//       } else {
//         $sentry.setUser(null)
//       }
//     },
//     {
//       immediate: true,
//       deep: true
//     }
//   )
// }

function initSentryClient () {
  const config = useRuntimeConfig()
  const nuxtApp = useNuxtApp()
  const router = useRouter()

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    trackComponents: true,
    tracesSampleRate: 0.2,
    environment: config.public.IS_STAGING ? 'stage' : 'production',
    release: config.public.CI_COMMIT_SHA,
    beforeSend (event, hint) {
      const error = hint.originalException
      if (typeof error === 'string') {
        return event
      }

      if (
        error &&
        error.name &&
        error.name.match(/ChunkLoadError/i)
      ) {
        return null
      }
      return event
    }
  })
}
