import { useSettingsStore } from '~/store/settings'
import { useSubscriptionStore } from '~/store/subscription'
import { useApi } from '~/composables/useApi'

interface State {
  actions: any // { [Settings.id]: Action[] } TODO: types
}

export const useActionsStore = defineStore('actions', {
  state: (): State => ({
    actions: {}
  }),
  getters: {
    currentActions: (state) => {
      const settingsStore = useSettingsStore()
      const currentSettingsId = settingsStore.currentSettings.id

      return state.actions[currentSettingsId]
    }
  },
  actions: {
    // Actions
    async getMyActions (payload: { cookies?: any, settingsId?: string }) {
      const api = useApi()
      const getEither = await api.actionsApi.getMyActions(payload)

      getEither.mapRight((actions) => {
        const actionsMap = actions.reduce((acc: any, action: any) => {
          if (!acc[action.settings]) {
            acc[action.settings] = [action]
            return acc
          }

          acc[action.settings].push(action)

          return acc
        }, { })

        this.SET_ACTIONS(actionsMap)
      })

      return getEither
    },
    async getAction (id: string) {
      const api = useApi()
      const getEither = await api.actionsApi.getAction(id)
      return getEither
    },
    async createAction (payload: any) {
      const api = useApi()
      const settingsStore = useSettingsStore()
      const currentSettingsId = settingsStore.currentSettings.id

      const { form, query } = payload
      const createEither = await api.actionsApi.createAction({
        settingsId: currentSettingsId,
        body: form,
        query
      })

      createEither.mapRight((action) => {
        this.ADD_ACTION(action, currentSettingsId)
      })

      return createEither
    },
    async updateAction (id: string, form: any, query: any) {
      const api = useApi()
      const settingsStore = useSettingsStore()

      const currentSettingsId = settingsStore.currentSettings.id

      const updateEither = await api.actionsApi.updateAction({ actionId: id, body: form, query })

      updateEither.mapRight((action: any) => {
        this.UPDATE_ACTION(action, currentSettingsId, false)
      })

      return updateEither
    },
    async deleteAction (actionId: string) {
      const api = useApi()
      const settingsStore = useSettingsStore()
      const currentSettingsId = settingsStore.currentSettings.id

      const deleteEither = await api.actionsApi.deleteAction({ settingsId: currentSettingsId, actionId })

      deleteEither.mapRight((action) => {
        this.DELETE_ACTION(currentSettingsId, action.id)
      })

      return deleteEither
    },
    // Mutations
    SET_ACTIONS (payload: any) {
      this.actions = payload
    },
    ADD_ACTION (action: any, settingsId: string) {
      if (!this.actions[settingsId]) {
        // Vue.set(this.actions, settingsId, []) // <- was, but gives ts error
        this.actions[settingsId] = []
      }

      this.actions[settingsId].push(action)
    },
    UPDATE_ACTION (action: any, settingsId: string, syncForFree: any) {
      this.actions[settingsId] = this.actions[settingsId]
        .map((oldAction: any) =>
          oldAction.id === action.id
            ? action
            : {
                ...oldAction,
                enabled: syncForFree ? false : oldAction.enabled
              }
        )
    },
    DELETE_ACTION (settingsId: string, actionId: string) {
      this.actions[settingsId] = this.actions[settingsId].filter((action: any) => action.id !== actionId)
    }
  }
})
