import { useApi } from '~/composables/useApi'

interface State {
  onboardingList: any, // TODO: Types for onboarding
  isShowExtraStep: boolean
}

export const useOnboardingStore = defineStore('onboarding', {
  state: (): State => ({
    onboardingList: null,
    isShowExtraStep: false
  }),
  actions: {
    // Actions
    async getUserOnboarding (cookies: any) {
      const api = useApi()
      const getEither = await api.onboardingApi.getUserOnboarding(cookies)

      getEither.mapRight((onboarding) => {
        this.SET_ONBOARDINGS(onboarding)
      })

      return getEither
    },

    setIsShowExtraStep (flag: boolean) {
      this.SET_IS_SHOW_EXTRA_STEP(flag)
    },

    async passOnboardingStep ({ feature, step }: any) {
      const api = useApi()
      await api.onboardingApi.passOnboardingStep({ feature, step })

      this.SET_ONBOARDINGS({
        ...this.onboardingList,
        [feature]: this.onboardingList[feature].filter((o: any) => !(o.feature === feature && o.step === step))
      })
    },
    // Mutations
    SET_ONBOARDINGS (payload: any) {
      this.onboardingList = payload
    },
    SET_IS_SHOW_EXTRA_STEP (isShowExtraStep: boolean) {
      this.isShowExtraStep = isShowExtraStep
    }
  }
})
