import { RuntimeConfig } from '@nuxt/schema'
import axios, { AxiosRequestConfig } from 'axios'
import { handleErrors, getApiBaseUrl } from '~/api/common'

export const onboardingApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/onboarding',
    withCredentials: true,
    validateStatus: () => true
  })

  return {
    async getUserOnboarding (cookies: string) {
      const config: AxiosRequestConfig = {
        headers: {}
      }

      if (cookies) {
        config.headers!.Cookie = cookies
      }

      const { data: payload } = await axiosBase.get('/list', config)

      return handleErrors(payload)
    },
    async passOnboardingStep ({ feature, step }: { feature: string, step: number }) {
      const { data: payload } = await axiosBase.post('/pass-step', {
        feature, step
      })

      return handleErrors(payload)
    }
  }
}
