import { parseCookies, setCookie } from '~/utils/cookies'
import { useStore } from '~/store/index'
import { useAbTestsStore } from '~/store/abTests'
import { useApi } from '~/composables/useApi'

interface State {
  user: any, // TODO: types for user
  editorFirstEnter: boolean | null,
  firstSectionEnter: string | null
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    user: null,
    editorFirstEnter: null,
    firstSectionEnter: null
  }),
  getters: {
    isAuthenticated: state => !!state.user
  },
  actions: {
    // Actions
    async registerSendCode ({ username, recaptchaV2Token }: any) {
      const api = useApi()
      const sendEither = await api.authApi.registerSend({ username, recaptchaV2Token })

      return sendEither
    },
    async registerVerifyCode (authCode: string, invitedBy: string, recaptchaToken: string, userInfo: any, username: string) {
      const rootStore = useStore()
      const abTestsStore = useAbTestsStore()
      const api = useApi()

      const verifyEither = await api.authApi.registerVerify({
        authCode,
        userInfo,
        invitedBy,
        recaptchaToken,
        username,
        details: null
      })

      await verifyEither.asyncMap(async () => {
        const cookiesRaw = decodeURIComponent(document.cookie)
        await rootStore.initEditor(rootStore.$state, cookiesRaw, parseCookies(cookiesRaw))
        await abTestsStore.refreshTests()
      })
      return verifyEither
    },
    async loginVerifySms (authCode: string, recaptchaToken: string, username:string) {
      const rootStore = useStore()
      const abTestsStore = useAbTestsStore()
      const api = useApi()

      const loginEither = await api.authApi.loginVerifySms({ authCode, recaptchaToken, username })

      return loginEither.asyncMap(async () => {
        const cookiesRaw = decodeURIComponent(document.cookie)
        await rootStore.initEditor(rootStore.$state, cookiesRaw, parseCookies(cookiesRaw))
        await abTestsStore.refreshTests()
      })
    },
    async loginByPassword (password: string, recaptchaToken: string, username: string) {
      const rootStore = useStore()
      const abTestsStore = useAbTestsStore()
      const api = useApi()

      const loginEither = await api.authApi.loginByPassword({ password, recaptchaToken, username })

      return loginEither.asyncMap(async () => {
        setCookie('__num', loginEither.value.id, 365)
        const cookiesRaw = decodeURIComponent(document.cookie)
        await rootStore.initEditor(rootStore.$state, cookiesRaw, parseCookies(cookiesRaw))
        await abTestsStore.refreshTests()
      })
    },
    async loginByToken (userId: string, token: string) {
      const api = useApi()
      const rootStore = useStore()
      const abTestsStore = useAbTestsStore()

      const loginEither = await api.authApi.loginByToken({
        userId,
        token
      })

      return loginEither.asyncMap(async () => {
        setCookie('__num', loginEither.value.id, 365)
        const cookiesRaw = decodeURIComponent(document.cookie)
        await rootStore.initEditor(rootStore.$state, cookiesRaw, parseCookies(cookiesRaw))
        await abTestsStore.refreshTests()
      })
    },
    async authViaTelegram (payloadFromTelegram: any, userInfo: any) {
      const api = useApi()
      const rootStore = useStore()
      const abTestsStore = useAbTestsStore()

      const authViaTelegramEither = await api.authApi.authViaTelegram({
        payloadFromTelegram,
        userInfo
      })

      await authViaTelegramEither.asyncMap(async () => {
        const cookiesRaw = decodeURIComponent(document.cookie)
        await rootStore.initEditor(rootStore.$state, cookiesRaw, parseCookies(cookiesRaw))
        await abTestsStore.refreshTests()
      })
      return authViaTelegramEither
    },
    async logout () {
      const api = useApi()
      const logoutEither = await api.authApi.logout()

      logoutEither.mapRight((payload) => {
        if (payload.success) {
          setCookie('__num', '0', -1)
          this.SET_USER(null)
        }
      })

      return logoutEither
    },
    async getUser (rawCookies: string | null) {
      const api = useApi()
      const getEither = await api.authApi.getMe(rawCookies)

      getEither.mapRight((user) => {
        this.SET_USER(user)
      })

      return getEither
    },
    async changeUsernameVerify (authCode: string, newUsername: string) {
      const api = useApi()
      const changeEither = await api.authApi.changeUsernameVerify({ authCode, newUsername })

      changeEither.mapRight((user) => {
        this.SET_USER(user)
      })

      return changeEither
    },
    async changeUserEmail (email: string) {
      const api = useApi()
      const changeEither = await api.userApi.changeEmail(email)

      changeEither.mapRight((user) => {
        this.SET_USER(user)
      })

      return changeEither
    },
    async changeUserTelegram (telegram: string) {
      const api = useApi()
      const changeEither = await api.userApi.changeTelegram(telegram)

      changeEither.mapRight((user) => {
        this.SET_USER(user)
      })

      return changeEither
    },
    unsubscribeUserFromRecurring () {
      this.SET_USER_RECURRING(null)
    },
    async checkEditorFirstEnter (userId: string) {
      const api = useApi()
      const isFirstEnter = await api.authApi.checkFirstEnter(userId)

      isFirstEnter.mapRight((value) => {
        this.SET_EDITOR_FIRST_ENTER(value)
      })

      return isFirstEnter
    },
    async checkFirstSection (userId: string, sectionName: string) {
      const api = useApi()
      const FirstSection = await api.authApi.checkFirstSectionEnter({ userId, sectionName })

      FirstSection.mapRight((value) => {
        this.SET_FIRST_SECTION_ENTER(value)
      })

      return FirstSection
    },
    // Mutations
    SET_USER (payload: any) {
      this.user = payload
    },
    SET_USER_RECURRING (payload: any) {
      this.user.paymentMethodId = payload
    },
    SET_EDITOR_FIRST_ENTER (payload: boolean) {
      this.editorFirstEnter = payload
    },
    SET_FIRST_SECTION_ENTER (payload: string) {
      this.firstSectionEnter = payload
    }
  }
})
