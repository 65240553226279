import { RuntimeConfig } from '@nuxt/schema'
import axios, { AxiosRequestConfig } from 'axios'
import { left, right } from '@sweet-monads/either'
import cookie from 'cookie'
import { handleErrors, getApiBaseUrl } from '~/api/common'
import { TooManyRequestsError, CaptchaNotPassedError } from '~/api/common.errors'
import { IRegisterSend, IRegisterVerify, ILoginSendSms, ILoginVerifySms, ILoginByPassword } from '~/api/auth/auth.dto'
import { WrongCodeError, WrongPasswordError } from '~/api/auth/auth.errors'

export const authApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/auth',
    withCredentials: true,
    validateStatus: status => status >= 200 && status < 500
  })

  return {
    async registerSend ({ username, recaptchaV2Token }: IRegisterSend) {
      const { data: payload } = await axiosBase.post('/register-send', { username, recaptchaV2Token })

      if (payload?.error?.code === TooManyRequestsError.code) {
        return left(new TooManyRequestsError())
      }

      return handleErrors(payload)
    },
    async registerVerify ({ username, authCode, userInfo, invitedBy, details, recaptchaToken }: IRegisterVerify) {
      const { data: payload } = await axiosBase.post('/register-verify', {
        username,
        authCode,
        invitedBy,
        userInfo,
        // localUserId: fingerprintService.getFingerprint(),
        yandexId: undefined,
        url: window.location.href,
        date: new Date(),
        details,
        pageTitle: document.title,
        utm: JSON.parse(cookie.parse(document.cookie)?.utm ?? null),
        recaptchaToken
      })

      if (payload?.error?.code === WrongCodeError.code) {
        return left(new WrongCodeError())
      }

      if (payload?.error?.code === CaptchaNotPassedError.code) {
        return left(new CaptchaNotPassedError())
      }

      return handleErrors(payload)
    },
    async registerGetSessionUsername () {
      const { data: payload } = await axiosBase.get('/register-session-username')

      return handleErrors(payload)
    },
    async loginSendSms ({ username, recaptchaV2Token }: ILoginSendSms) {
      const { data: payload } = await axiosBase.post('/login-send-sms', { username, recaptchaV2Token })

      if (payload?.error?.code === TooManyRequestsError.code) {
        return left(new TooManyRequestsError())
      }

      return handleErrors(payload)
    },
    async loginVerifySms ({ authCode, recaptchaToken, username }: ILoginVerifySms) {
      const { data: payload } = await axiosBase.post('/login-verify-sms', {
        authCode, recaptchaToken, username
      })

      if (payload?.error?.code === WrongCodeError.code) {
        return left(new WrongCodeError())
      }

      if (payload?.error?.code === CaptchaNotPassedError.code) {
        return left(new CaptchaNotPassedError())
      }

      return handleErrors(payload)
    },
    async loginGetSessionUsername () {
      const { data: payload } = await axiosBase.get('/login-session-username')

      return handleErrors(payload)
    },
    async loginByToken ({ userId, token }: { userId: string; token: string }) {
      const { status, data: payload } = await axiosBase.post(`/login/${userId}/${token}`)

      if (status === 200) {
        return right(payload.data)
      }

      return left(payload)
    },
    async logout () {
      const { data } = await axiosBase.post('/logout')

      return handleErrors(data)
    },
    async getMe (rawCookies: string | null) {
      const config: AxiosRequestConfig = {
        headers: {}
      }

      if (rawCookies) {
        config.headers!.Cookie = rawCookies
      }
      const { data: payload } = await axiosBase.get('/me', config)

      return handleErrors(payload)
    },
    async checkUsernameUnique ({ username }: { username: string }) {
      const { data: payload } = await axiosBase.post('/check-user', {
        username
      })

      return payload
    },
    async changeUsernameSend ({ newUsername }: { newUsername: string }) {
      const { data: payload } = await axiosBase.post('/change-username-send', { newUsername })

      return handleErrors(payload)
    },
    async changeUsernameVerify ({ authCode, newUsername }: { authCode: string; newUsername: string }) {
      const { status, data: payload } = await axiosBase.post('/change-username-verify', {
        authCode, newUsername
      })

      if (status === 403) {
        return left(new WrongCodeError())
      }

      return handleErrors(payload)
    },
    async removeAccount () {
      const { data: payload } = await axiosBase.post('/remove-account')

      return handleErrors(payload)
    },
    async setPassword (password: string) {
      const { data: payload } = await axiosBase.put('/set-password', { password })

      return handleErrors(payload)
    },
    async changePassword ({ password, newPassword }: { password: string; newPassword: string }) {
      const { data: payload } = await axiosBase.put('/change-password', { password, newPassword })

      if (payload?.error?.code === WrongPasswordError.code) {
        return left(new WrongPasswordError())
      }

      return handleErrors(payload)
    },
    async checkUserAuthStrategy (username: string) {
      const { data: payload } = await axiosBase.get('/strategy', { params: { username } })

      return handleErrors(payload)
    },
    async loginByPassword ({ password, recaptchaToken, username }: ILoginByPassword) {
      const { data: payload } = await axiosBase.post('/login-by-password', {
        password, recaptchaToken, username
      })

      if (payload?.error?.code === WrongPasswordError.code) {
        return left(new WrongPasswordError())
      }

      if (payload?.error?.code === CaptchaNotPassedError.code) {
        return left(new CaptchaNotPassedError())
      }

      return handleErrors(payload)
    },
    async authViaTelegram ({ payloadFromTelegram, userInfo }: { payloadFromTelegram: Record<string, any>; userInfo: unknown }) {
      const { data: payload } = await axiosBase.post('/auth-via-telegram', {
        userInfo,
        ...payloadFromTelegram
      })

      return handleErrors(payload)
    },
    async checkFirstEnter (userId: string) {
      const { data: payload } = await axiosBase.post('/check-first-enter', { userId })

      return handleErrors(payload)
    },
    async checkFirstSectionEnter ({ userId, sectionName }: { userId: string; sectionName: string }) {
      const { data: payload } = await axiosBase.post('/check-first-section-enter', { userId, sectionName })

      return handleErrors(payload)
    }
  }
}
