import { RuntimeConfig } from '@nuxt/schema'
import axios, { AxiosRequestConfig } from 'axios'
import { handleErrors, getApiBaseUrl } from '~/api/common'

export const subscriptionPlanApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/subscription-plans',
    withCredentials: true,
    validateStatus: () => true
  })

  return {
    async getAllSubscriptionPlans (cookies: string) {
      const config: AxiosRequestConfig = {
        headers: {}
      }
      if (cookies) {
        config.headers!.Cookie = cookies
      }

      const { data: payload } = await axiosBase.get('', config)

      return handleErrors(payload)
    }
  }
}
