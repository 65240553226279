import { acceptHMRUpdate, defineStore } from 'pinia'
import { NuxtServerInitOptions } from '~/plugins/init.server'
import { useAuthStore } from '~/store/auth'
import { useSettingsStore } from '~/store/settings'
import { useOnboardingStore } from '~/store/onboarding'
import { useIntegrationsStore } from '~/store/integrations'
import { useSubscriptionPlansStore } from '~/store/subscriptionPlans'
import { useSubscriptionStore } from '~/store/subscription'
import { useActionsStore } from '~/store/actions'

export const useStore = defineStore('root', {
  actions: {
    async nuxtServerInit ({ cookiesRaw, cookies }: NuxtServerInitOptions) {
      const store = useStore()

      await store.initEditor(store, cookiesRaw, cookies)
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async initEditor (store: any, cookiesRaw: string | undefined, cookies: Record<string, string> | undefined) {
      const authStore = useAuthStore()
      const settingsStore = useSettingsStore()
      const onboardingStore = useOnboardingStore()
      const integrationsStore = useIntegrationsStore()
      const subscriptionPlansStore = useSubscriptionPlansStore()
      const subscriptionStore = useSubscriptionStore()
      const actionsStore = useActionsStore()

      settingsStore.resetCurrentSettings(null)

      await Promise.all([
        authStore.getUser(cookiesRaw !== undefined ? cookiesRaw : null),
        settingsStore.getSettings(cookiesRaw !== undefined ? cookiesRaw : null),
        onboardingStore.getUserOnboarding(cookiesRaw !== undefined ? cookiesRaw : null),
        integrationsStore.getIntegrations(cookiesRaw !== undefined ? cookiesRaw : null),
        subscriptionPlansStore.getSubscriptionPlans(cookiesRaw !== undefined ? cookiesRaw : null),
        subscriptionStore.getSubscription(cookiesRaw !== undefined ? cookiesRaw : null),
        actionsStore.getMyActions({ cookies: cookiesRaw !== undefined ? cookiesRaw : null })
      ])
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot))
}
